import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PageResponse } from '@mp/shared/data-access';

import { ExportJobProvider } from '../types/export-job-provider';
import { ArticleExport } from '../types/export.type';
import { ExportsFilters } from '../types/exports-filters.type';

@Injectable({
  providedIn: 'root',
})
export class ExportsApiService {
  private readonly path = '/api/contentmanagement/article-exports';

  constructor(private httpClient: HttpClient) {}

  getList(filters: ExportsFilters): Observable<{ list: ArticleExport[]; numberOfAllItems: number }> {
    return this.httpClient.get<PageResponse<ArticleExport>>(this.path, { params: filters }).pipe(
      map(({ data, pagination }): { list: ArticleExport[]; numberOfAllItems: number } => ({
        list: data,
        numberOfAllItems: pagination.totalRowCount,
      })),
    );
  }

  deleteExportItem(exportItemId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.path}/${exportItemId}`);
  }

  regenerateExportItem(exportItemId: string): Observable<void> {
    return this.httpClient.put<void>(`${this.path}/${exportItemId}/restart`, '');
  }

  /**
   * Ermittelt die zur Verfügung stehenden Export-Routinen.
   */
  getProviders(): Observable<readonly ExportJobProvider[]> {
    return this.httpClient.get<readonly ExportJobProvider[]>(`${this.path}/types`);
  }
}
